nav {
  width: 230px;
  min-width: 230px;
  padding: 10px;
  flex-direction: column;
  font-size: 14px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  .logo {
    width: 80px;
    padding: 16px 0 0 16px;
  }

  .user-card {
    width: 168px;
    margin: 20px auto 10px;
    display: flex;
    padding: 10px 16px;
    border-radius: .5rem;
    background: var(--block-color);
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
    }

    .user-infos {
      color: var(--text-color);
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        span:nth-child(2) {
          font-weight: bold;
          margin-left: 4px;
        }
      }

      &>span {
        font-size: 10px;
      }
    }
  }

  .menu-container {
    margin: 0 auto;
    width: 200px;

    ul {
      list-style: none;
      padding: 0;

      li {
        a {
          text-decoration: none;
          color: var(--text-color);
        }

        .menu-link-selected {
          font-weight: bold;
        }

        .disable-link {
          pointer-events: none;
          color: lightgrey;
        }

        .accordion {
          height: 0px;
          transition-duration: 0.4s;
          overflow: hidden;
          padding-left: 16px;

          li {
            margin-top: 10px;
          }

          li:last-child {
            padding-bottom: 16px;
          }
        }

        .menu-parent {
          display: flex;
          align-items: center;
          padding: 10px 16px;
          border-radius: .5rem;
          cursor: pointer;

          &>* {
            pointer-events: none;
          }

          span {
            margin: 0 auto 0 10px;
            color: var(--text-color)
          }

          .menu-icon {
            width: 14px;
            height: 14px;
            padding: 10px;
            background: var(--block-color);
            border-radius: .5rem;
            box-shadow: 0 .25rem .375rem -.0625rem hsla(0, 0%, 8%, .12), 0 .125rem .25rem -.0625rem hsla(0, 0%, 8%, .07);
            display: flex;
            align-items: center;
            justify-content: center;
            fill: var(--text-color)
          }

          .expand_icon {
            fill: var(--text-color)
          }
        }

        &>.active-menu {
          color: var(--text-color);
          background: var(--block-color);
          box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

          .menu-icon {
            background: #fdc64b;
          }

          span {
            font-weight: 600;
          }
        }
      }
    }

    &>div {
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 16px;
      color: var(--text-color)
    }
  }

  .menu-version {
    width: 168px;
    color: var(--text-color);
    margin: 10px auto 10px;
    padding: 10px 16px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.9em;

    span>span:nth-child(1) {
      font-weight: bold;
    }

    &__number {
      font-weight: bold;
      color: var(--colored-element-color);
    }
  }
}