body {
  background: var(--background-color) !important;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

:root[data-theme="light"] {
  --primary-color: #FFB100;
  --secondary-color: #1C1A1A;
  --background-color: #f8f9fa;
  --text-color: #1C1A1A;
  --block-color: #FFF;
  --colored-element-color: #FFB100;
  --divider-color: #e1e1e1;
  --activity-color: #e1f5fd;
  --activity-text-color: #48829d;
  --avatar-color: #bdbdbd;
  --reference-color: #d0d0d0;
  --progress-color: #ffeab8;
  --subtitle-color: #aeaeae;
  --scroll-track-color: #f3f3f3;
  --scroll-thumb-color: #d9d9d9
}

:root[data-theme="dark"] {
  --primary-color: #1C1A1A;
  --secondary-color: #FFB100;
  --background-color: #1d1d1d;
  --text-color: #FFF;
  --block-color: #2a2a2a;
  --colored-element-color: #FFB100;
  --divider-color: #3e3e3e;
  --activity-color: #1d1d1d;
  --activity-text-color: #FFF;
  --avatar-color: #1d1d1d;
  --reference-color: #737373;
  --progress-color: #5f4300;
  --subtitle-color: #7f7f7f;
  --scroll-track-color: #302d2d;
  --scroll-thumb-color: #141313
}


// 
// ─── TOOLS ───────────────────────────────────────
//
.tools-header {
  margin-bottom: 30px;

  h1,
  h2 {
    position: relative;

    &::after {
      content: " ";
      width: 100px;
      height: 3px;
      background: var(--colored-element-color);
      position: absolute;
      bottom: -6px;
      left: 0;
    }
  }

  p {
    color: var(--subtitle-color)
  }
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-track-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
}